<template>
    <div>
        <span class="small">Statuts des cadeaux</span>

        <div class="progress">
            <div v-bind:class="'progress-bar progress-bar-striped bg-'+GiftStatus.PLANNED.color" role="progressbar"
                 v-bind:style="'width: '+(plannedGifts/totalGifts*100)+'%'">
                {{GiftStatus.PLANNED.text}} ({{plannedGifts}})
            </div>
            <div v-bind:class="'progress-bar progress-bar-striped bg-'+GiftStatus.BOUGHT.color" role="progressbar"
                 v-bind:style="'width: '+(boughtGifts/totalGifts*100)+'%'">
                {{GiftStatus.BOUGHT.text}} ({{boughtGifts}})
            </div>
            <div v-bind:class="'progress-bar progress-bar-striped bg-'+GiftStatus.PACKED.color" role="progressbar"
                 v-bind:style="'width: '+(packedGifts/totalGifts*100)+'%'">
                {{GiftStatus.PACKED.text}} ({{packedGifts}})
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { GiftStatus } from "./store";

    export default {
        name: "statistics-status",
        computed: mapState({
            totalGifts: state => state.gifts.length,
            plannedGifts: state => countStatus(state, GiftStatus.PLANNED.value),
            boughtGifts: state => countStatus(state, GiftStatus.BOUGHT.value),
            packedGifts: state => countStatus(state, GiftStatus.PACKED.value),
        }),
        data: function () {
            return { GiftStatus: GiftStatus, };
        },
    }

    function countStatus(state, status) {
        return state
            .gifts
            .filter(g => g.status === status)
            .length;
    }
</script>

<style scoped>

</style>