import store from "./store";

function getAll() {
    return new Promise(resolve =>
        fetch(env.backend.url + '/recipients', {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
    );
}

function insert({ name, email }) {
    fetch(env.backend.url + '/recipients', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email }),
    })
        .then((res) => res.json())
        .then((data) =>
            store.commit('addRecipient', { _id: data, name, email })
        );
}

function update({ _id, name, email }) {
    store.commit('saveRecipient', { _id, name, email });
    fetch(env.backend.url + '/recipients', {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ _id, name, email }),
    });
}

function deleteOne(id) {
    store.commit('deleteRecipient', id);
    fetch(env.backend.url + '/recipients', {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ _id: id }),
    });
}

export { getAll, insert, update, deleteOne };
