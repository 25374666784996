<template>
    <div class="styled-gift" v-bind:style="styleObject">
        <i></i>
        <i></i>
    </div>
</template>

<script>
    export default {
        name: "styled-gift-item",
        props: {
            gift: Object,
        },
        data: function () {
            return {
                transform: 'translateX(' + getRandom(-30, 30) + 'px)',
            };
        },
        computed: {
            styleObject: function () {
                return {
                    width: Math.min(Math.max(Math.log(this.gift.price || 100) * 30, 90), 335) + 'px',
                    height: Math.min(Math.max(Math.log(this.gift.price || 100) * 25, 80), 300) + 'px',
                    transform: this.transform,
                };
            },
        },
    }

    function getRandom(min, max) {
        return Math.random() * (max - min) + min;
    }
</script>

<style scoped>

</style>