import Vuex from "vuex";
import Vue from 'vue';

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        recipients: [],
        gifts: []
    },
    mutations: {
        addRecipients(state, recipients) {
            state.recipients = state.recipients.concat(recipients);
        },
        addRecipient(state, recipient) {
            state.recipients.push(recipient);
        },
        saveRecipient(state, { _id, name, email }) {
            const recipient = state.recipients.find(recipient => recipient._id === _id);
            recipient.name = name;
            recipient.email = email;
        },
        deleteRecipient(state, id) {
            state.recipients = state.recipients.filter(recipient => recipient._id !== id);
            state.gifts = state.gifts.filter(gift => gift.recipientId !== id);
        },

        addGifts(state, gifts) {
            state.gifts = state.gifts.concat(gifts);
        },
        addGift(state, gift) {
            state.gifts.push(gift);
        },
        saveGift(state, { _id, name, price, recipientId, status }) {
            const gift = state.gifts.find(gift => gift._id === _id);
            gift.name = name;
            gift.price = price;
            gift.recipientId = recipientId;
            gift.status = status;
        },
        deleteGift(state, id) {
            state.gifts = state.gifts.filter(gift => gift._id !== id);
        },
    }
});

export default store;

export const GiftStatus = {
    PLANNED: {
        value: 'planned',
        text: 'Prévu',
        color: 'secondary',
    },
    BOUGHT: {
        value: 'bought',
        text: 'Acheté',
        color: 'info',
    },
    PACKED: {
        value: 'packed',
        text: 'Emballé',
        color: 'success',
    },
};
