<template>
    <div>
        <span class="small">Budget <span>(total : {{totalBudget}} CHF)</span></span>

        <div class="progress mb-1" v-for="budget in budgetPerRecipient">
            <div class="progress-bar progress-bar-striped text-dark" role="progressbar"
                 v-bind:style="'width: '+(budget.value/totalBudget*100)+'%'">
                {{budget.name}} ({{budget.value}} CHF)
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "statistics-budget",
        computed: mapState({
            totalBudget: state => state.gifts
                .map(g => g.price)
                .reduce((a, b) => a + b, 0),
            budgetPerRecipient: state => {
                let budget = {};

                for (let r of state.recipients) {
                    budget[r._id] = {
                        name: r.name,
                        value: state.gifts
                            .filter(g => g.recipientId === r._id)
                            .map(g => g.price)
                            .reduce((a, b) => a + b, 0),
                    };
                }

                return budget;
            }
        }),
    }
</script>

<style scoped>
    .progress-bar {
        background-color: #abcdef;
    }
</style>