<template>
    <div id="app">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-xl-8">
                    <div class="row">
                        <div class="col-md-12 col-xl-6 py-3">
                            <santa-claus class="mx-auto my-5"></santa-claus>

                            <statistics-view></statistics-view>
                        </div>

                        <div class="col-md-12 col-xl-6 py-3">
                            <recipients-list class="mb-3"></recipients-list>

                            <gifts-list></gifts-list>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-xl-4">
                    <styled-gift-list></styled-gift-list>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "./store";
    import RecipientsList from "./recipients-list";
    import GiftsList from "./gifts-list";
    import SantaClaus from "./santa-claus";
    import { getAll } from "./recipientsService";
    import StyledGiftList from "./styled-gift-list";
    import StatisticsView from "./statistics-view";
    import { getAllGifts } from "./giftsService";

    export default {
        name: 'app',
        components: { StatisticsView, StyledGiftList, SantaClaus, GiftsList, RecipientsList },
        store,
        mounted: function () {
            getAll().then(data => store.commit('addRecipients', data));
            getAllGifts().then(data => store.commit('addGifts', data));
        },
    }
</script>

<style lang="css">
    .card-columns {
        column-count: 2 !important;
    }
</style>