<template>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title d-flex align-items-center mb-0">
                Destinataires
                <button class="btn btn-primary ml-auto" @click="openAdd">Ajouter</button>
            </h5>
        </div>

        <transition name="slide-fade">
            <div class="card-body bg-blue-light" v-show="addOpen">
                <recipient-edit clear-on-save v-bind:on-save="add" v-bind:on-cancel="closeAdd"></recipient-edit>
            </div>
        </transition>

        <transition-group name="list" tag="div" class="list-group list-group-flush">
            <recipient-item v-for="recipient in recipients" v-bind:key="recipient._id"
                            v-bind:recipient="recipient"></recipient-item>
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import store from "./store";
    import RecipientEdit from "./recipient-edit";
    import RecipientItem from "./recipient-item";
    import { insert } from "./recipientsService";
    import { EventBus } from './event-bus';

    export default {
        name: "recipients-list",
        components: { RecipientItem, RecipientEdit },
        computed: mapState({
            recipients: 'recipients',
        }),
        data: () => (
            {
                addOpen: false,
            }
        ),
        methods: {
            openAdd: function () {
                EventBus.$emit('edit-item-open');
                this.addOpen = true;
            },
            closeAdd: function () {
                this.addOpen = false;
            },
            add: function ({ name, email }) {
                insert({ name, email });
                this.addOpen = false;
            },
            deleteRecipient: function (id) {
                store.commit('deleteRecipient', id);
            },
        },
        mounted: function () {
            EventBus.$on('edit-item-open', this.closeAdd);
        },
        beforeDestroy: function () {
            EventBus.$off('edit-item-open', this.closeAdd);
        },
    }
</script>

<style scoped>

</style>