<template>
    <form class="text-right" @submit="save">
        <div class="form-group">
            <input type="text" class="form-control mb-3" placeholder="Nom" v-model="dName">
        </div>
        <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" v-model="dEmail">
        </div>
        <button class="btn btn-secondary" @click="onCancel">Annuler</button>
        <button type="submit" class="btn btn-primary ml-2">Sauver</button>
    </form>
</template>

<script>
    export default {
        name: "recipient-edit",
        props: {
            name: String,
            email: String,
            onSave: Function,
            onCancel: Function,
            clearOnSave: Boolean,
        },
        data: function () {
            return {
                dName: this.name,
                dEmail: this.email,
            };
        },
        methods: {
            save: function (e) {
                this.onSave({
                    name: this.$data.dName,
                    email: this.$data.dEmail,
                });
                if (this.clearOnSave) {
                    this.$data.dName = '';
                    this.$data.dEmail = '';
                }
                e.preventDefault();
            }
        }
    }
</script>

<style scoped>

</style>