const env = {
    "mongo": {
        user: "jdonzallaz",
        password: "sabile456"
    },
    backend: {
        url: 'https://si-gifts.herokuapp.com',
    },
};

export default env;