<template>
    <div class="styled-wrapper-out">
        <div class="styled-fade"></div>
        <transition-group name="list" tag="div" appear
                          class="d-flex flex-column justify-content-end align-items-center styled-wrapper-in">
            <styled-gift-item v-for="gift in gifts" v-bind:key="gift._id" v-bind:gift="gift"></styled-gift-item>
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import StyledGiftItem from "./styled-gift-item";

    export default {
        name: "styled-gift-list",
        components: { StyledGiftItem },
        computed: mapState({
            gifts: state => [...state.gifts].reverse(),
        }),
    }
</script>

<style scoped>

</style>