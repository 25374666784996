<template>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Résumé</h5>
            <div>
                <statistics-status></statistics-status>
                <statistics-budget></statistics-budget>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { GiftStatus } from "./store";
    import StatisticsStatus from "./statistics-status";
    import StatisticsBudget from "./statistics-budget";

    export default {
        name: "statistics-view",
        components: { StatisticsBudget, StatisticsStatus },
        computed: mapState({
            totalGifts: state => state.gifts.length,
        }),
        data: function () {
            return { GiftStatus: GiftStatus, };
        }
    }
</script>

<style scoped>

</style>