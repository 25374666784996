<template>
    <div class="card">
        <div class="card-body">
            <h5 class="card-title d-flex align-items-center mb-0">
                Cadeaux
                <button class="btn btn-primary ml-auto" @click="openAdd">Ajouter</button>
            </h5>
        </div>

        <transition name="slide-fade">
            <div class="card-body bg-blue-light" v-show="addOpen">
                <gift-edit clear-on-save v-bind:on-save="add" v-bind:on-cancel="closeAdd"></gift-edit>
            </div>
        </transition>

        <transition-group name="list" tag="div" class="list-group list-group-flush">
            <gift-item v-for="gift in gifts" v-bind:key="gift._id" v-bind:gift="gift"></gift-item>
        </transition-group>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import GiftEdit from "./gift-edit";
    import { insertGift } from "./giftsService";
    import GiftItem from "./gift-item";
    import { EventBus } from './event-bus';

    export default {
        name: "gifts-list",
        components: { GiftItem, GiftEdit },
        computed: mapState({
            gifts: 'gifts',
        }),
        data: () => (
            {
                addOpen: false,
            }
        ),
        methods: {
            openAdd: function () {
                EventBus.$emit('edit-item-open');
                this.addOpen = true;
            },
            closeAdd: function () {
                this.addOpen = false;
            },
            add: function (gift) {
                this.addOpen = false;
                insertGift(gift);
            },
        },
        mounted: function () {
            EventBus.$on('edit-item-open', this.closeAdd);
        },
        beforeDestroy: function () {
            EventBus.$off('edit-item-open', this.closeAdd);
        },
    }
</script>

<style scoped>

</style>