import store from "./store";

function getAllGifts() {
    return new Promise(resolve =>
        fetch(env.backend.url + '/gifts', {
            method: 'GET',
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
    );
}

function insertGift(gift) {
    fetch(env.backend.url + '/gifts', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gift),
    })
        .then((res) => res.json())
        .then(data =>
            store.commit('addGift', { _id: data, ...gift })
        );
}

function updateGift(gift) {
    store.commit('saveGift', gift);
    fetch(env.backend.url + '/gifts', {
        method: 'PUT',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gift),
    });
}

function deleteGift(id) {
    store.commit('deleteGift', id);
    fetch(env.backend.url + '/gifts', {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ _id: id }),
    });
}

export { getAllGifts, insertGift, updateGift, deleteGift };
