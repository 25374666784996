<template>
    <div v-bind:class="{'list-group-item':true, shadow:editOpen, 'bg-blue-light':editOpen}">
        <transition name="fade" mode="out-in">
            <recipient-edit
                    v-bind:name="recipient.name"
                    v-bind:email="recipient.email"
                    v-bind:on-save="save"
                    v-bind:on-cancel="closeEdit"
                    v-if="editOpen"></recipient-edit>
            <div class="d-flex align-items-center" v-else>
                {{recipient.name}}
                <font-awesome-icon icon="edit" class="button-icon ml-auto" @click="openEdit"></font-awesome-icon>
                <font-awesome-icon icon="trash" class="button-icon ml-3"
                                   @click="deleteRecipient(recipient._id)"></font-awesome-icon>
            </div>
        </transition>
    </div>
</template>

<script>
    import RecipientEdit from "./recipient-edit";
    import { update, deleteOne } from "./recipientsService";
    import { EventBus } from './event-bus';

    export default {
        name: "recipient-item",
        components: { RecipientEdit },
        props: {
            recipient: Object,
        },
        data: function () {
            return {
                editOpen: false,
            };
        },
        methods: {
            openEdit: function (event) {
                EventBus.$emit('edit-item-open');
                this.editOpen = true;
            },
            closeEdit: function (event) {
                this.editOpen = false;
            },
            deleteRecipient: function (id) {
                deleteOne(id);
            },
            save: function ({ name, email }) {
                update({ _id: this.recipient._id, name, email });
                this.editOpen = false;
            },
        },
        mounted: function () {
            EventBus.$on('edit-item-open', this.closeEdit);
        },
        beforeDestroy: function () {
            EventBus.$off('edit-item-open', this.closeEdit);
        },
    }
</script>

<style scoped>
    .button-icon {
        color: #555;
    }

    .button-icon:hover {
        color: #222;
    }
</style>