<template>
    <form class="text-right" @submit="save">
        <div class="form-group">
            <input type="text" class="form-control mb-3" placeholder="Nom" v-model="dName">
        </div>
        <div class="input-group mb-3">
            <input type="text" class="form-control" placeholder="Prix" v-model="dPrice">
            <div class="input-group-append">
                <span class="input-group-text">CHF</span>
            </div>
        </div>
        <div class="form-group">
            <select class="custom-select" v-model="dStatus">
                <option v-for="status in GiftStatus" v-bind:value="status.value">
                    {{ status.text }}
                </option>
            </select>
        </div>
        <div class="form-group">
            <select class="custom-select" v-model="dRecipientId">
                <option selected value="">Destinataire</option>
                <option v-for="recipient in recipients" v-bind:value="recipient._id">
                    {{ recipient.name }}
                </option>
            </select>
        </div>
        <button class="btn btn-secondary" @click="onCancel">Annuler</button>
        <button type="submit" class="btn btn-primary ml-2">Sauver</button>
    </form>
</template>

<script>
    import { mapState } from 'vuex';
    import { GiftStatus } from "./store";

    export default {
        name: "gift-edit",
        props: {
            name: String,
            price: Number,
            recipientId: String,
            status: String,
            onSave: Function,
            onCancel: Function,
            clearOnSave: Boolean,
        },
        computed: mapState({
            recipients: 'recipients',
        }),
        data: function () {
            return {
                dName: this.name,
                dPrice: this.price,
                dRecipientId: this.recipientId || '',
                dStatus: this.status || 'planned',
                GiftStatus: GiftStatus,
            };
        },
        methods: {
            save: function (e) {
                this.onSave({
                    name: this.$data.dName,
                    price: Number(this.$data.dPrice) || 0,
                    recipientId: this.$data.dRecipientId,
                    status: this.$data.dStatus,
                });
                if (this.clearOnSave) {
                    this.$data.dName = '';
                    this.$data.dPrice = '';
                    this.$data.dRecipientId = '';
                    this.$data.dStatus = GiftStatus.PLANNED.value;
                }
                e.preventDefault();
            }
        }
    }
</script>

<style scoped>

</style>