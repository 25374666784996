import env from './env';

window.env = env;

import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './app/App.vue';
import './app/style/main.scss';

library.add(faEdit, faTrash);
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    el: '#app',
    render: h => h(App)
});
