<template>
    <div v-bind:class="{'list-group-item':true, shadow:editOpen, 'bg-blue-light':editOpen}">
        <transition name="fade" mode="out-in">
            <gift-edit
                    v-bind:name="gift.name"
                    v-bind:price="gift.price"
                    v-bind:recipient-id="gift.recipientId"
                    v-bind:status="gift.status"
                    v-bind:on-save="save"
                    v-bind:on-cancel="closeEdit"
                    v-if="editOpen"></gift-edit>
            <div v-else>
                <div class="d-flex align-items-center">
                    {{gift.name}}
                    <font-awesome-icon icon="edit" @click="openEdit" class="button-icon ml-auto"></font-awesome-icon>
                    <font-awesome-icon icon="trash" class="button-icon ml-3"
                                       @click="deleteGift(gift._id)"></font-awesome-icon>
                </div>
                <div class="d-flex align-items-center">
                    <span class="text-muted">Pour {{recipientName || '...'}}</span>
                    <span v-bind:class="'ml-2 badge badge-'+status.color">{{status.text}}</span>
                    <span class="text-muted ml-auto">{{gift.price}} CHF</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import GiftEdit from "./gift-edit";
    import { updateGift, deleteGift } from "./giftsService";
    import { GiftStatus } from "./store";
    import { EventBus } from './event-bus';

    export default {
        name: "gift-item",
        components: { GiftEdit },
        props: {
            gift: Object,
        },
        computed: {
            status: function () {
                return Object
                    .values(GiftStatus)
                    .find(s => s.value === this.gift.status);
            },
            ...mapState({
                recipientName(state) {
                    const recipient = state.recipients
                        .find(recipient => recipient._id === this.gift.recipientId);
                    return recipient ? recipient.name : '';
                }
            }),
        },
        data: function () {
            return {
                editOpen: false,
            };
        },
        methods: {
            openEdit: function () {
                EventBus.$emit('edit-item-open');
                this.editOpen = true;
            },
            closeEdit: function () {
                this.editOpen = false;
            },
            deleteGift: function (id) {
                deleteGift(id);
            },
            save: function (gift) {
                updateGift({ _id: this.gift._id, ...gift });
                this.editOpen = false;
            },
        },
        mounted: function () {
            EventBus.$on('edit-item-open', this.closeEdit);
        },
        beforeDestroy: function () {
            EventBus.$off('edit-item-open', this.closeEdit);
        },
    }
</script>

<style scoped>
    .button-icon {
        color: #555;
    }

    .button-icon:hover {
        color: #222;
    }
</style>